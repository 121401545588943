<template>
  <LandingPageLayout>
    <div class="container">
      <section class="white-section py-5 stagger__in-ease-left--1 px-1 px-md-0">
        <div style="width: 100%; max-width: 700px; margin: auto">
          <div class="text-link">About us</div>
          <h2 class="h2 mt-1">
            We are your innovative
            <span class="simple"> tech recruitment </span> and
            <span class="simple"> outsourcing solution </span>
          </h2>

          <p class="p mt-2" style="color: #344054">
            Infohob is Nigeria’s plug-and-play technical recruitment solution
            that helps you design your future workforce.
          </p>
        </div>
      </section>

      <img src="/img/about-us.png" alt="" />
      <div
        style="width: 100%; max-width: 815px; margin: auto"
        class="px-1 px-md-0"
      >
        <p class="p mt-2">
          At Infohob, we believe in the value of delivering top-quality service
          for both sides of the job search. Our strength comes from our power to
          deliver results by offering an all-encompassing job search platform.
          We profoundly connect a network of diverse and talented job-seekers
          and top-tier companies. However, we don’t just connect people with
          companies — <strong> we make career goals happen.</strong>

          <br />
          <br />
          As industry leaders, we support employers and job seekers in every
          step of the search and hiring process. We offer talent on tap. When
          you work with us, you’ll enjoy a personalized service that keeps you
          in the loop while saving you time and money and delivering world-class
          results.
        </p>
      </div>
    </div>

    <DarkSection class="mt-4"></DarkSection>
  </LandingPageLayout>
</template>

<script>
import LandingPageLayout from "@/layouts/LandingPageLayout";
import TheTalent from "../components/TheTalent.vue";
import TheTab from "../components/TheTab.vue";
import PercentageCircle from "vue-css-percentage-circle";
import DarkSection from "./sections/DarkSection.vue";

export default {
  name: "LandingPage",
  components: {
    LandingPageLayout,
    TheTalent,
    TheTab,
    PercentageCircle,
    DarkSection
  },
  data() {
    return {
      formData: {},
      activeSlide: 1,
      slidesLength: 2,
      tabs: ["Recruitment", "Outsourcing", "Training"],
      activeTab: "Recruitment",
      talentAvatars: [
        "/img/avatars/Ellipse-24.png",
        "/img/avatars/Ellipse-26.png",
        "/img/avatars/Ellipse-25.png",
        "/img/avatars/Ellipse-27.png",
        "/img/avatars/Ellipse-28.png",
        "/img/avatars/Ellipse-29.png"
      ],
      techStacks: ["Blockchain", "Data science", "Web 3"],
      recruitmentList: [
        {
          title: "Get in Touch",
          description:
            "We’ll listen to your needs and connect you to a rich pool of qualified professionals who closely match your expectations in terms of skills, qualifications, experience, seniority, salary range and cultural fit."
        },
        {
          title: "Candidate Assessments",
          description:
            "We conduct background checks including National ID, qualifications and previous employment, psychological and aptitude tests, preliminary interviews, and candidate reporting via score cards."
        },
        {
          title: "Pick Your Preferred Candidate",
          description:
            "We send you a list of the best three candidates. You conduct just three interviews and make an offer to your top choice. If any candidate rejects the offer, you can replace them with either of the other two."
        },
        {
          title: "Get Started",
          description:
            "When you find your ideal candidate, the fee is just 10% of annual salary for junior and senior roles. If the hired candidate leaves within 3 months, then we will replace the candidate with no extra cost."
        }
      ],
      outsourcingList: [
        {
          title: "Full Recruitment Service",
          description:
            "We’ll handle the entire hiring process for you as explained above."
        },
        {
          title: "Use Us as Your HR Department",
          description:
            "We’ll support you and your new team members post-hiring, doing everything an in-house HR department would do."
        },
        {
          title: "Technical Supervision",
          description:
            "A qualified Technology Analyst from Infohob will provide your staff with expert technical supervision in their specialized work. Includes a contract signed by both parties. With our highly personalized service, you can have as much or as little involvement as you like, whether you prefer to be kept in the loop at every step or simply relax knowing your people are in safe hands."
        }
      ],

      trainingList: [
        { title: "You send a request" },
        {
          title:
            "We assess your needs and design training modules to your specifications"
        },
        {
          title:
            "We assess your needs and design training modules to your specifications"
        },
        {
          title:
            "We implement the feedback to create your ideal training course"
        }
      ]
    };
  },

  computed: {
    activeList() {
      if (this.activeTab === "Recruitment") {
        return this.recruitmentList;
      } else if (this.activeTab === "Outsourcing") {
        return this.outsourcingList;
      } else if (this.activeTab === "Training") {
        return this.trainingList;
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.tab-list {
  min-height: 111px;
  background: #ffffff;
  border-radius: 8px;
}

.tab-list__item {
  padding: 30px 22px;
  border-bottom: 1px solid #eaecf0;
}
.tab-list__item:last-child {
  border-bottom: 0;
}

.tab-list__title {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #101828;
}
.tab-list__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #667085;
}

.c100 > span {
  color: #2c2c2c !important;
}
</style>
